<template>
  <div>
    <cp-head-table
      title="System Emails"
      title-icon="ios-build"
    />

    <cp-table
      ref="cpTable"
      get-data-action="systemEmails/getEmailTemplates"
      :fields="fields"
    >
      <template
        slot="from"
        slot-scope="{ rowData }"
      >
        <a :href="`mailto:${rowData.item.from}`">
          {{ rowData.item.from }}
        </a>
      </template>
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>
      <template
        slot="updatedAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.updatedAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          title="Edit"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          Edit
        </b-btn>
      </template>
    </cp-table>

    <cp-system-emails-modal
      ref="CpSystemEmail"
      :title-data="titleData"
      :email-data="emailData"
      @onSaveChanges="saveChanges($event, 'updateEmailTemplateById')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import fields from './fields';

import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import CpSystemEmailsModal from './system-email-modal';

export default {
  name: 'SystemEmailTemplates',

  metaInfo: {
    title: 'System Email Templates',
  },

  components: {
    CpHeadTable,
    CpTable,
    CpSystemEmailsModal,
  },

  data() {
    return {
      fields,
      titleData: {
        type: 'Edit',
        icon: 'md-create',
      },
      emailData: {},
    };
  },

  methods: {
    ...mapActions('systemEmails',
      [
        'getEmailTemplateById',
        'updateEmailTemplateById',
      ]),
    saveChanges(data, actionName) {
      this[actionName](data).then(() => {
        this.$refs.CpSystemEmail.$refs.systemEmail.hide();
        this.$refs.cpTable.updateTableData();
      });
    },
    toEditModal({ id }) {
      this.getEmailTemplateById(id).then(({ data }) => {
        this.emailData = data;
        this.$refs.CpSystemEmail.$refs.systemEmail.show();
      });
    },
  },
};
</script>
