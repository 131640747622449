<template>
  <!-- eslint-disable vue/no-v-html -->
  <cp-general-modal
    ref="systemEmail"
    size="lg"
    title="Edit system email"
    title-icon="ion ion-md-create"
    :default-value="emailData"
    @onOk="handleSubmit"
  >
    <cp-input
      name="name"
      label="Name"
      validate="required"
      disabled
    />

    <cp-input
      name="subject"
      label="Subject"
      validate="required"
    />

    <cp-input
      name="title"
      label="Title"
    />

    <cp-input
      name="from"
      label="From"
      validate="email"
    />

    <cp-input
      name="allowedVariables"
      label="Allowed variables"
      validate="required"
      disabled
    />

    <cp-textarea
      name="variableDescription"
      label="Template Variables"
      :rows="5"
      disabled
    />
    <cp-textarea
      name="text"
      label="Email Text"
      :rows="5"
      validate="required"
    />
    <cp-textarea
      name="content"
      label="Email HTML"
      :rows="5"
      validate="required"
    />

    <b-btn
      v-if="model.content"
      icon="ion ion-md-link"
      @click="htmlTempIsOpened = !htmlTempIsOpened"
    >
      <i :class="htmlTempIsOpened ? 'ion-md-eye-off' : 'ion-md-eye'" />
      Preview
    </b-btn>

    <div
      v-if="htmlTempIsOpened"
      v-dompurify-html="model.content"
      class="cp-html-content mt-3"
    />

    <div class="mt-3">
      <div class="cp-bg-grey">
        <div class="d-flex justify-content-between align-items-center  w-50 py-2 pl-2 pr-lg-5">
          <span>
            Created
          </span>
          <span>
            <strong>
              {{ model.createdAt | dateFilter }}
            </strong>
          </span>
        </div>
      </div>

      <div class="cp-bg-grey mt-3">
        <div class="d-flex justify-content-between align-items-center w-50 py-2 pl-2 pr-lg-5">
          <span>
            Updated
          </span>
          <span>
            <strong>
              {{ model.updatedAt | dateFilter }}
            </strong>
          </span>
        </div>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

export default {
  name: 'SystemModalModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpTextarea,
  },
  props: {
    emailData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      model: {},
      htmlTempIsOpened: false,
    };
  },
  watch: {
    emailData(val) {
      this.model = Object.assign({}, val);
    },
  },
  methods: {
    handleSubmit(data) {
      const formData = { ...data };
      if (!formData.from) {
        formData.from = null;
      }
      this.$emit('onSaveChanges', formData);
    },
  },
};
</script>

<style lang="scss">
  .cp-bg-grey {
    background-color: rgba(10, 23, 39, 0.025);
  }

  .cp-html-content {
    overflow: scroll;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: rgba(10, 23, 39, 0.025);
  }
</style>
