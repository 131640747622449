export default [
  { key: 'name', tdClass: 'align-middle' },
  { key: 'from', tdClass: 'align-middle' },
  { key: 'title', tdClass: 'align-middle' },
  { key: 'subject', tdClass: 'align-middle' },
  {
    key: 'allowedVariables', sortable: true, label: 'Allowed Vars', tdClass: 'align-middle',
  },
  {
    key: 'variableDescription', label: 'Var Description', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'updatedAt', sortable: true, label: 'Updated', tdClass: 'align-middle',
  },
  {
    key: 'createdAt', sortable: true, label: 'Created', tdClass: 'align-middle',
  },
  { key: 'actions', label: ' ', tdClass: 'text-nowrap align-middle text-center' },
];
